#domain-banner{
    background-position: center;
    background-origin: content-box;
    background-attachment: scroll;
}

span{
    color: var(--Grays-Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3px;
}

.activity-summary{
    width: 100%;
}

#domain-description{
    background: var(--color-secondary);
    padding-bottom: 189px;
}

#domain-description p {
    color: var(--Grays-Black, #000);
}

.picture-thumbnail{
    max-height: 197px;
    width: 100%;
    object-fit: cover;
}

#region-presentation{
    padding-top: 189px;
}

#region-presentation h1.title{
    color: #FFF;
}

#region-presentation p {
    color: #FFF;
}

.region-thumbnail{
    max-height: 656px;
    object-fit: cover;
    width: 100%;
}