#events-options article.event-option p{
    color: var(--Grays-Black, #000);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
}

.event-option img{
    aspect-ratio: 15/12;
}

