@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #00515B;
    --color-secondary: #D6EDE5;
    --color-tertiary: #00515B;
  }
}