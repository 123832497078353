.icon-contact{
    height: 50px;
    width: 50px;
}
#events-options .subtitle{
    color: #FFF;
    font-family: Montserrat;
    font-size: 35px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.5px;
}

#contactez-nous form{
    border-radius: 24px;
}

#contactez-nous form h2{
    color: var(--Grays-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2px;
}

#topic-list input[type="radio"]{
    display: none;
}

#topic-list label{
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid var(--Grays-Black, #000);
    color: var(--Grays-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 1.6px;
    padding: 10px 20px;
}

#topic-list input:checked + label {
    border-radius: 20px;
    background: var(--Grays-Black, #000);
    color: white;
}