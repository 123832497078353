#meet-the-host{
    background: var(--color-secondary);
    padding-bottom: 189px;
}

#workshops-presentation article h2{
    color: #FFF;
    text-align: center;
    font-family: "League Spartan";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
}

#workshops-presentation article{
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

#workshops-presentation article div{
    position: absolute;
    top: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(5px);
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    align-items: center;
    justify-items: center;
    padding: 160px 48px;
}

#workshops-presentation article img{
    min-width: 380px;
    aspect-ratio: 3/4;
    object-fit: cover;
    object-position: center;
}

dialog{
    width: calc(100vw - 80px);
    max-width: 800px;
    min-width: 500px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

dialog .modal{
    width: auto;
}

#workshop-modal p,
#workshop-modal span{
    color: var(--Grays-Black, #000);
    font-family: ClementePDaf;
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 2.4px;
    overflow-wrap: break-word;
    line-break: auto;
    word-wrap: break-word;
}

#workshop-modal p.bold,
#workshop-modal span.bold,
#workshop-modal h2{
    color: var(--Grays-Black, #000);
    font-family: ClementePDak;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: none;
}

#workshop-modal p.light-bold,
#workshop-modal span.light-bold{
    color: var(--Grays-Black, #000);
    font-family: ClementePDah;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
}

#workshop-modal p.little-title,
#workshop-modal span.little-title{
    font-weight: bold;
    font-style: normal;
}

#workshop-modal p:not(.italic):not(.bold),
#workshop-modal span:not(.italic):not(.bold){
    font-family: ClementePDae;
    font-style: normal;
}

#workshop-banner{
    border-radius: 30px 30px 0 0;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(5px);
}

#host-modal p{
    color: #FFF;
    font-family: ClementePDae;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 4px;
}