#des-hébergements-uniques article{
    width: 380px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
}

.icon{
    width: 40px;
}

#des-hébergements-uniques article h2{
    color: var(--Grays-Black, #000);
    text-align: center;
    font-family: "League Spartan";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.4px;
    text-transform: uppercase;
}

#des-hébergements-uniques article span{
    color: var(--Grays-Black, #000);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
}

#des-hébergements-uniques article button{
    border: 4px solid var(--Grays-Black, #000);
    color: var(--Grays-Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 2.6px;
    background: none;
    box-shadow: none;
    padding: 14px 22px;
    border-radius: 20px;
    height: 64px;
}

#des-hébergements-uniques article button.link{
    color: var(--Grays-Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 2.4px;
    text-decoration-line: underline;
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    text-transform: lowercase;
}

#details-summary{
    background: var(--color-secondary);
}

.rental-icons{
    width: 60px;
    height: 60px;
}

#details-summary span{
    color: var(--Grays-Black, #000);
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.4px;
}

.icon-close{
    width: 48px;
    height: 48px;
}

#rates-table{
    border-collapse: separate;
    table-layout: fixed;
    width: 740px;
}

#rates-table tr td:first-of-type{
    text-align: left;
}

#rates-table td{
    padding: 12px;
    background: var(--color-secondary);
    border: 0.089rem solid white;
}

#rates-table th{
    padding: 12px;
    border: 0.089rem solid white;
    color: white;
}

#rates-table tbody{
    display: block;
    max-height: calc(100vh - 300px - 5.5rem);
    overflow-y: auto;
}

#rates-table thead,
#rates-table tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
}

#rates-table thead{
    width: calc(100% - 16px);
}