@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: #00515B;
    --color-secondary: #D6EDE5;
    --color-tertiary: #00515B;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRoZW1lL3RhaWx3aW5kLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSIsImZpbGUiOiJ0YWlsd2luZC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAdGFpbHdpbmQgYmFzZTtcbkB0YWlsd2luZCBjb21wb25lbnRzO1xuQHRhaWx3aW5kIHV0aWxpdGllcztcblxuQGxheWVyIGJhc2Uge1xuICA6cm9vdCB7XG4gICAgLS1jb2xvci1wcmltYXJ5OiAjMDA1MTVCO1xuICAgIC0tY29sb3Itc2Vjb25kYXJ5OiAjRDZFREU1O1xuICAgIC0tY29sb3ItdGVydGlhcnk6ICMwMDUxNUI7XG4gIH1cbn0iXX0= */