.nav__li{
    height: 60px;
}

.nav__li a.current svg path{
    stroke: white;
}

.nav__li a:not(.current){
    color: var(--color-primary);
}

.nav__li a:not(.current) svg path{
    stroke: var(--color-primary);
}

.nav__li:not(.home) a span{
    font-size: 18px;
    font-family: 'Mosafin';
    font-weight: 600;
}

.current{
    display: block;
    background: var(--color-primary);
}

.current span{
    color: white;
}

.nav__li a{
    display: grid;
    align-items: center;
    justify-items: center;
    height: 60px;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
}

.nav__li a span {
    text-align: center;
    font-family: 'Mosafin';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

nav {
    height: 72px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

nav ul {
    height: 100%;
}

.home a{
    display: grid;
    justify-items: center;
    align-items: center;
    height: 60px;
    width: 72px;
    border-radius: 50px;
}