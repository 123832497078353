@font-face {
    font-family: 'Mosafin';
    src: url('/src/assets/fonts/Mosafin-SemiBold.eot');
    src: url('/src/assets/fonts/Mosafin-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Mosafin-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/Mosafin-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/Mosafin-SemiBold.ttf') format('truetype'),
        url('/src/assets/fonts/Mosafin-SemiBold.svg#Mosafin-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mosafin';
    src: url('/src/assets/fonts/Mosafin-Medium.eot');
    src: url('/src/assets/fonts/Mosafin-Medium.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Mosafin-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/Mosafin-Medium.woff') format('woff'),
        url('/src/assets/fonts/Mosafin-Medium.ttf') format('truetype'),
        url('/src/assets/fonts/Mosafin-Medium.svg#Mosafin-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mosafin';
    src: url('/src/assets/fonts/Mosafin-Black.eot');
    src: url('/src/assets/fonts/Mosafin-Black.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Mosafin-Black.woff2') format('woff2'),
        url('/src/assets/fonts/Mosafin-Black.woff') format('woff'),
        url('/src/assets/fonts/Mosafin-Black.ttf') format('truetype'),
        url('/src/assets/fonts/Mosafin-Black.svg#Mosafin-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mosafin';
    src: url('/src/assets/fonts/Mosafin-ExtraBold.eot');
    src: url('/src/assets/fonts/Mosafin-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Mosafin-ExtraBold.woff2') format('woff2'),
        url('/src/assets/fonts/Mosafin-ExtraBold.woff') format('woff'),
        url('/src/assets/fonts/Mosafin-ExtraBold.ttf') format('truetype'),
        url('/src/assets/fonts/Mosafin-ExtraBold.svg#Mosafin-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mosafin';
    src: url('/src/assets/fonts/Mosafin-Bold.eot');
    src: url('/src/assets/fonts/Mosafin-Bold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Mosafin-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Mosafin-Bold.woff') format('woff'),
        url('/src/assets/fonts/Mosafin-Bold.ttf') format('truetype'),
        url('/src/assets/fonts/Mosafin-Bold.svg#Mosafin-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Thin.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Thin.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Thin.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Thin.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Thin.svg#LeagueSpartan-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Black.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Black.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Black.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Black.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Black.svg#LeagueSpartan-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Regular.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Regular.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Regular.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Regular.svg#LeagueSpartan-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Medium.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Medium.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Medium.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Medium.svg#LeagueSpartan-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Bold.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Bold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Bold.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Bold.svg#LeagueSpartan-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-Light.eot');
    src: url('/src/assets/fonts/LeagueSpartan-Light.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-Light.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-Light.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-Light.svg#LeagueSpartan-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-ExtraLight.eot');
    src: url('/src/assets/fonts/LeagueSpartan-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-ExtraLight.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-ExtraLight.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-ExtraLight.svg#LeagueSpartan-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-SemiBold.eot');
    src: url('/src/assets/fonts/LeagueSpartan-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-SemiBold.svg#LeagueSpartan-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'League Spartan';
    src: url('/src/assets/fonts/LeagueSpartan-ExtraBold.eot');
    src: url('/src/assets/fonts/LeagueSpartan-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/LeagueSpartan-ExtraBold.woff2') format('woff2'),
        url('/src/assets/fonts/LeagueSpartan-ExtraBold.woff') format('woff'),
        url('/src/assets/fonts/LeagueSpartan-ExtraBold.svg#LeagueSpartan-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Bold.eot');
    src: url('/src/assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Bold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Bold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Black.eot');
    src: url('/src/assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Black.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Black.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Black.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Light.eot');
    src: url('/src/assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Light.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Light.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Light.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-BlackItalic.eot');
    src: url('/src/assets/fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-BlackItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-BlackItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-BlackItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-ExtraBold.eot');
    src: url('/src/assets/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-ExtraBold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-ExtraLightItalic.eot');
    src: url('/src/assets/fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-BoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-BoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-BoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Italic.eot');
    src: url('/src/assets/fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Italic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Italic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Italic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-ExtraLight.eot');
    src: url('/src/assets/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-ExtraLight.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-ExtraLight.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-ExtraBoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-SemiBold.eot');
    src: url('/src/assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-LightItalic.eot');
    src: url('/src/assets/fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-LightItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-LightItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-LightItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Regular.eot');
    src: url('/src/assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Regular.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Regular.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-ThinItalic.eot');
    src: url('/src/assets/fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-ThinItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-ThinItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-ThinItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-SemiBoldItalic.eot');
    src: url('/src/assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Medium.eot');
    src: url('/src/assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Medium.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Medium.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Medium.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-Thin.eot');
    src: url('/src/assets/fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-Thin.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-Thin.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-Thin.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/src/assets/fonts/Montserrat-MediumItalic.eot');
    src: url('/src/assets/fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/Montserrat-MediumItalic.woff2') format('woff2'),
        url('/src/assets/fonts/Montserrat-MediumItalic.woff') format('woff'),
        url('/src/assets/fonts/Montserrat-MediumItalic.ttf') format('truetype'),
        url('/src/assets/fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDaj';
    src: url('/src/assets/fonts/ClementePDaj-RegularItalic.eot');
    src: url('/src/assets/fonts/ClementePDaj-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDaj-RegularItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDaj-RegularItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDaj-RegularItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDaj-RegularItalic.svg#ClementePDaj-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDab';
    src: url('/src/assets/fonts/ClementePDab-HairlineItalic.eot');
    src: url('/src/assets/fonts/ClementePDab-HairlineItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDab-HairlineItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDab-HairlineItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDab-HairlineItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDab-HairlineItalic.svg#ClementePDab-HairlineItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDae';
    src: url('/src/assets/fonts/ClementePDae-Light.eot');
    src: url('/src/assets/fonts/ClementePDae-Light.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDae-Light.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDae-Light.woff') format('woff'),
        url('/src/assets/fonts/ClementePDae-Light.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDae-Light.svg#ClementePDae-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDad';
    src: url('/src/assets/fonts/ClementePDad-ExtraLightItalic.eot');
    src: url('/src/assets/fonts/ClementePDad-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDad-ExtraLightItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDad-ExtraLightItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDad-ExtraLightItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDad-ExtraLightItalic.svg#ClementePDad-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDak';
    src: url('/src/assets/fonts/ClementePDak-SemiBold.eot');
    src: url('/src/assets/fonts/ClementePDak-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDak-SemiBold.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDak-SemiBold.woff') format('woff'),
        url('/src/assets/fonts/ClementePDak-SemiBold.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDak-SemiBold.svg#ClementePDak-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDac';
    src: url('/src/assets/fonts/ClementePDac-ExtraLight.eot');
    src: url('/src/assets/fonts/ClementePDac-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDac-ExtraLight.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDac-ExtraLight.woff') format('woff'),
        url('/src/assets/fonts/ClementePDac-ExtraLight.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDac-ExtraLight.svg#ClementePDac-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDah';
    src: url('/src/assets/fonts/ClementePDah-BookItalic.eot');
    src: url('/src/assets/fonts/ClementePDah-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDah-BookItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDah-BookItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDah-BookItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDah-BookItalic.svg#ClementePDah-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDai';
    src: url('/src/assets/fonts/ClementePDai-Regular.eot');
    src: url('/src/assets/fonts/ClementePDai-Regular.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDai-Regular.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDai-Regular.woff') format('woff'),
        url('/src/assets/fonts/ClementePDai-Regular.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDai-Regular.svg#ClementePDai-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDaa';
    src: url('/src/assets/fonts/ClementePDaa-Hairline.eot');
    src: url('/src/assets/fonts/ClementePDaa-Hairline.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDaa-Hairline.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDaa-Hairline.woff') format('woff'),
        url('/src/assets/fonts/ClementePDaa-Hairline.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDaa-Hairline.svg#ClementePDaa-Hairline') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDal';
    src: url('/src/assets/fonts/ClementePDal-SemiBoldItalic.eot');
    src: url('/src/assets/fonts/ClementePDal-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDal-SemiBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDal-SemiBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDal-SemiBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDal-SemiBoldItalic.svg#ClementePDal-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDag';
    src: url('/src/assets/fonts/ClementePDag-Book.eot');
    src: url('/src/assets/fonts/ClementePDag-Book.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDag-Book.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDag-Book.woff') format('woff'),
        url('/src/assets/fonts/ClementePDag-Book.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDag-Book.svg#ClementePDag-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDaf';
    src: url('/src/assets/fonts/ClementePDaf-LightItalic.eot');
    src: url('/src/assets/fonts/ClementePDaf-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDaf-LightItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDaf-LightItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDaf-LightItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDaf-LightItalic.svg#ClementePDaf-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDao';
    src: url('/src/assets/fonts/ClementePDao-Heavy.eot');
    src: url('/src/assets/fonts/ClementePDao-Heavy.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDao-Heavy.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDao-Heavy.woff') format('woff'),
        url('/src/assets/fonts/ClementePDao-Heavy.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDao-Heavy.svg#ClementePDao-Heavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDap';
    src: url('/src/assets/fonts/ClementePDap-HeavyItalic.eot');
    src: url('/src/assets/fonts/ClementePDap-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDap-HeavyItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDap-HeavyItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDap-HeavyItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDap-HeavyItalic.svg#ClementePDap-HeavyItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDam';
    src: url('/src/assets/fonts/ClementePDam-Bold.eot');
    src: url('/src/assets/fonts/ClementePDam-Bold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDam-Bold.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDam-Bold.woff') format('woff'),
        url('/src/assets/fonts/ClementePDam-Bold.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDam-Bold.svg#ClementePDam-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDar';
    src: url('/src/assets/fonts/ClementePDar-UltraBoldItalic.eot');
    src: url('/src/assets/fonts/ClementePDar-UltraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDar-UltraBoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDar-UltraBoldItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDar-UltraBoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDar-UltraBoldItalic.svg#ClementePDar-UltraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDan';
    src: url('/src/assets/fonts/ClementePDan-BoldItalic.eot');
    src: url('/src/assets/fonts/ClementePDan-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDan-BoldItalic.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDan-BoldItalic.woff') format('woff'),
        url('/src/assets/fonts/ClementePDan-BoldItalic.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDan-BoldItalic.svg#ClementePDan-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'ClementePDaq';
    src: url('/src/assets/fonts/ClementePDaq-UltraBold.eot');
    src: url('/src/assets/fonts/ClementePDaq-UltraBold.eot?#iefix') format('embedded-opentype'),
        url('/src/assets/fonts/ClementePDaq-UltraBold.woff2') format('woff2'),
        url('/src/assets/fonts/ClementePDaq-UltraBold.woff') format('woff'),
        url('/src/assets/fonts/ClementePDaq-UltraBold.ttf') format('truetype'),
        url('/src/assets/fonts/ClementePDaq-UltraBold.svg#ClementePDaq-UltraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

