#logo-background {
    position: absolute;
    top: 10px;
    left: 10px;
    clip-path: url(#logo);
    background-color: rgba(0,0,0,0.25);
    border-radius: 50%;
    backdrop-filter: blur(2px);
    height: 400px;
    width: 400px;
    margin-top: 24px;
    margin-left: 24px;
}

#logo{
    background-repeat: no-repeat;
    background-size: cover;
}

#logo-container{
    height: 468px;
    width: 468px;
}
