@import './tailwind.css';
@import './fonts.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section:first-of-type > div:first-child {
  min-height: 100vh;
  display: grid;
  grid: none;
  align-items: end;
  justify-items: center;
}

section:first-of-type > div:first-child > img:last-child{
  width: 100px;
  height: 100px;
}

section:first-of-type > div:first-child > *:last-child{
  position: relative;
  bottom: 11%;
  transform: translate(0, 11%);
}

.banner{
  background-position: center;
  background-origin: content-box;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
}

button,
.modal,
#workshops-presentation article,
#workshops-presentation article div,
#workshops-presentation article img,
dialog,
dialog .modal,
#workshop-banner + section,
.picture-thumbnail,
.region-thumbnail,
#des-hébergements-uniques article img:not(.icon),
#des-hébergements-uniques article,
img.food-preview,
.event-option,
.event-option img{
  border-radius: 30px;
}

#workshops-presentation,
#words-about-workshop,
#host-modal,
#contactez-nous,
#rates-table th,
#des-hébergements-uniques,
#events-options,
#region-presentation{
    background: var(--color-tertiary);
}

textarea{
    border-radius: 12px;
    border: 2px solid var(--Grays-Black, #000);
    resize: none;
}

input[type="text"]{
  border-bottom: 2px solid var(--Grays-Black, #000);
}

textarea{
  height: 138px;
}

textarea,
input[type="text"]{
  padding: 12px 16px;
  color: var(--Grays-Black, #000);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 1.6px;
  width: 100%;
}

label{
  color: var(--Grays-Black, var(--Grays-Black, #000));
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.8px;
}

button{
  padding: 16px 40px;
  width: max-content;
  font-size: 38px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'League Spartan';
  background: var(--color-primary);
  color: white;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.25);
}

button[type="submit"]{
  border-radius: 20px;
  background: var(--Grays-Black, #000);
  padding: 16px 71px;
  color: var(--White, #FFF);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "League Spartan";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  box-shadow: none;
}

button.reverse-colors{
  color: var(--color-primary);
  background: white;
}

button.outlined-black{
  color: black;
  background: transparent;
  border: 4px solid black;
  box-shadow: none;
  text-transform: none;
  font-style: italic;
  font-weight: 600;
  height: 68px;
  padding-top: 4px;
  border-radius: 20px;
}

button.outlined-white{
  color: white;
  background: transparent;
  border: 4px solid white;
  box-shadow: none;
  text-transform: none;
  font-style: italic;
  font-weight: 600;
  height: 68px;
  padding-top: 4px;
  border-radius: 20px;
}

button.little-btn{
  padding: 0 40px;
  height: 53px;
  font-size: 24px;
}

.absolute-center{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-h-center{
  left: 50%;
  transform: translate(-50%, 0);
}

.absolute-l-center{
  top: 50%;
  transform: translate(0, -50%);
}

header{
  top: 52px !important;
}

p {
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.2px;
}

.little-logo{
    height: 250px;
    width: 250px;
}

address span{
  color: var(--Grays-Black, #000);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2.4px;
}

button.appearence-none{
  background: none;
  box-shadow: none;
}

.title{
  color: var(--Grays-Black, #000);
  text-align: center;
  font-family: "League Spartan";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.title.text-white,
.subtitle.text-white{
  color: white;
}

.title.text-left{
  text-align: left;
}

.subtitle{
  color: var(--Grays-Black, #000);
  font-family: Montserrat;
  font-size: 35px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.5px;
}

.italic-p{
  text-align: center;
  font-family: Montserrat;
  font-size: 35px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3.5px;
}

.blured-background{
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
}

.modal{
  font-size: 13px;
  top: 40px;
  z-index: 101;
  width: max-content;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

h2{
  color: var(--Grays-Black, #000);
  text-align: center;
  font-family: "League Spartan";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.little-title{
  margin-top: 8px;
}

dialog{
  display: block;
}

.wave{
  min-height: 100px;
}